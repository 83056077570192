import React from "react";
import "./Main.css";
import History from "../History/History";
import Games from "../Games/Games";
import ContactUs from "../ContactUs/ContactUs";
import Popup from "../Popup/Popup";
import { LangContext } from "../../context/LangContext";
import mainCover from "../../images/main-cover.webp";
import mainCoverMob from "../../images/main-cover-mob.webp"

const MainCover = () => {
  const lang = React.useContext(LangContext);

  return (
    <main className="main">
      {/* <Popup/> */}
      <section className="cover">
        <img
          className="cover__img cover__pc"
          src={mainCover}
          alt="capa principal"
          loading="lazy"
          width="1440"
          height="700"
        />
        <img
          className="cover__img cover__mob"
          src={mainCoverMob}
          alt="capa principal"
          width="360"
          height="1025"
        />
        {lang === "en" ? (
          <div className="cover__overlay">
            <h1 className="cover__header col-w">
              Discover a world of excitement and fun in the heart of the
              Mediterranean. Welcome to the website about slot machines in
              casinos in Greece!
            </h1>
            <p className="col-w t-16 cover__text">
              On our website you will find everything you need to know about
              slot machines in casinos in Greece, from their history to the
              variety of games and feedback options. Immerse yourself in the
              fascinating world of casino gambling and find out how to spend
              your time in a fun and rewarding way.
            </p>
          </div>
        ) : (
          <div className="cover__overlay">
            <h1 className="cover__header col-w">
              Ανακαλύψτε έναν κόσμο ενθουσιασμού και διασκέδασης στην καρδιά του
              Μεσογειακός. Καλώς ήρθατε στον ιστότοπο σχετικά με τον κουλοχέρη
              μηχανήματα σε καζίνο στην Ελλάδα!
            </h1>
            <p className="col-w t-16 cover__text">
              Στην ιστοσελίδα μας θα βρείτε όλα όσα πρέπει να γνωρίζετε
              κουλοχέρηδες σε καζίνο στην Ελλάδα, από την ιστορία τους μέχρι την
              ποικιλία παιχνίδια και επιλογές ανατροφοδότησης. Βυθιστείτε στο
              συναρπαστικό κόσμο των τυχερών παιχνιδιών καζίνο και μάθετε πώς να
              περάσετε το χρόνο σας σε ένα διασκεδαστικό και ανταποδοτικό τρόπο.
            </p>
          </div>
        )}
      </section>
      <History />
      <Games />
      <ContactUs />
    </main>
  );
};

export default MainCover;
