import React from "react";
import "./Footer.css";
import age from "../../images/age.svg";
import telegram from "../../images/telegram.svg";
import inst from "../../images/instagram.svg";
import { Link } from "react-router-dom";
import { LangContext } from "../../context/LangContext";

const Footer = () => {
  const lang = React.useContext(LangContext);

  return (
    <footer id="contacts" className="footer">
      <div className="footer__el1">
        <p className="t-15">
          {lang === "en"
            ? "If you would like to contact us or give us feedback, you are welcome to do so. You can contact us via"
            : "Εάν θέλετε να επικοινωνήσετε μαζί μας ή να μας δώσετε σχόλια, μπορείτε να το κάνετε. Μπορείτε να επικοινωνήσετε μαζί μας μέσω"}
          <br></br>e-mail:<br></br>
          <a href="mailto:koryfaiesstoichimatikes@gmail.com ">
            koryfaiesstoichimatikes@gmail.com
          </a>
        </p>
        <p className="footer__cookie-header">
          {lang === "en" ? "Important information:" : "Σημαντικές πληροφορίες:"}
        </p>
        {lang === "en" ? (
          <p className="t-15">
            <span className="footer__span">koryfaiesstoichimatikes.com</span>{" "}
            uses cookies to ensure the best possible quality. By visiting the
            website, you accept the use of cookies.
          </p>
        ) : (
          <p className="t-15">
            <span className="footer__span">koryfaiesstoichimatikes.com</span>{" "}
            χρησιμοποιεί cookies για να εξασφαλίσετε την καλύτερη δυνατή
            ποιότητα. Με την επίσκεψη στον ιστότοπο, αποδέχεστε τη χρήση
            cookies.
          </p>
        )}

        <Link to="cookies" className="t-15">
          {lang === "en" ? "More information" : "Περισσότερες πληροφορίες"}
        </Link>
      </div>
      <div className="footer__el2">
        <p className="t-15">
          {lang === "en"
            ? "All information on our website is for information purposes only."
            : "Περισσότερες πληροφορίεςΌλες οι πληροφορίες στον ιστότοπό μας προορίζονται μόνο για ενημερωτικούς σκοπούς"}
        </p>
        <Link to="privacy" className="t-15">
          {lang === "en" ? "Privacy policy" : "Πολιτική απορρήτου"}
          Privacy policy
        </Link>
      </div>
      <div className="footer__el3">
        <img
          src={age}
          alt="age limit 18+"
          title="age limit 18+"
          width="54"
          height="54"
        />
        <div className="footer__link-container">
          <a href="#">
            <img src={inst} alt="instagram icon" title="instagram icon" />
          </a>
          <a href="#">
            <img src={telegram} alt="telegram icon" title="telegram icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
