import React, { useState } from "react";
import axios from "axios";
import "./ContactUs.css";
import { LangContext } from "../../context/LangContext";
import mail from "../../images/mail-icon.svg";

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const lang = React.useContext(LangContext);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("mail.php", email)
      .then((response) => {
        console.log("Message send");
      })
      .catch((error) => {
        console.log("Error sending message");
      });
  };

  return (
    <section className="contacts width">
      <img
        src={mail}
        alt="mail sign"
        title="mail sign"
        width="78"
        height="78"
      />
      {lang === "en" ? (
        <>
          <h3 className="contacts__header">Leave your mark!</h3>
          <p className="contacts__text">
            We are glad that you have visited our website and are interested in
            our news and offers. To avoid missing important updates, special
            offers and exclusive news, subscribe to our newsletter!
          </p>
        </>
      ) : (
        <>
          <h3 className="contacts__header">Αφήστε το σημάδι σας!</h3>
          <p className="contacts__text">
            Χαιρόμαστε που επισκεφτήκατε τον ιστότοπό μας και σας ενδιαφέρει τα
            νέα και οι προσφορές μας. Για να μην χάσετε σημαντικές ενημερώσεις,
            ειδικές προσφορές και αποκλειστικά νέα, εγγραφείτε στο newsletter
            μας!
          </p>
        </>
      )}

      <form onSubmit={handleSubmit} className="contacts__form">
        <div className="form__input-container">
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder={
              lang === "en" ? "Enter your email" : "Εισαγάγετε το email σας"
            }
            className="form__input t-15"
          />
          <button type="submit" className="form__btn">
            {lang === "en" ? "Subscribe" : "Συνεισφέρω"}
          </button>
        </div>
      </form>
      {lang === "en" ? (
        <p className="t-15 contacts__bottom-text">
          We never share your data with third parties.
          <br></br>See our privacy policy for more information.
        </p>
      ) : (
        <p className="t-15 contacts__bottom-text">
          Δεν μοιραζόμαστε ποτέ τα δεδομένα σας με τρίτους.
          <br></br>Δείτε την πολιτική απορρήτου μας για περισσότερες
          πληροφορίες.
        </p>
      )}
    </section>
  );
};

export default ContactUs;
