import React from "react";
import { LangContext } from "../../context/LangContext";

const Cookies = () => {
  const lang = React.useContext(LangContext);

  return (
    <main className="privacy width">
      {lang === "en" ? (
        <section>
          <h1 className="h3-size">
            Cookie Policy for koryfaiesstoichimatikes.com
          </h1>
          <p className="t-17">
            This is the Cookie Policy for koryfaiesstoichimatikes.com,
            accessible from koryfaiesstoichimatikes.com
          </p>
          <h2 className="h3-size">What Are Cookies</h2>
          <p className="t-17">
            As is common practice with almost all professional websites this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies. We will also share how you can prevent these
            cookies from being stored however this may downgrade or 'break'
            certain elements of the sites functionality.
          </p>
          <h2 className="h3-size">How We Use Cookies</h2>
          <p className="t-17">
            We use cookies for a variety of reasons detailed below.
            Unfortunately in most cases there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to this site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not in
            case they are used to provide a service that you use.
          </p>
          <h2 className="h3-size">Disabling Cookies</h2>
          <p className="t-17">
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of the
            this site. Therefore it is recommended that you do not disable
            cookies.
          </p>
          <h2 className="h3-size">The Cookies We Set</h2>
          <p className="t-17">
            Cookies used to configure the site To ensure that you have a good
            experience on this site, we offer a functionality to set your
            preferences for how this site runs when you use it. To remember your
            preferences, we need to set cookies so that this information can be
            recalled whenever you interact with a page that is affected by your
            preferences.
          </p>
          <h2 className="h3-size">Third Party Cookies</h2>
          <p className="t-17">
            In some special cases we also use cookies provided by trusted third
            parties. The following section details which third party cookies you
            might encounter through this site.
          </p>
          <p className="t-17">
            This site uses Google Analytics which is one of the most widespread
            and trusted analytics solution on the web for helping us to
            understand how you use the site and ways that we can improve your
            experience. These cookies may track things such as how long you
            spend on the site and the pages that you visit so we can continue to
            produce engaging content.
          </p>
          <p className="t-17">
            For more information on Google Analytics cookies, see the official
            Google Analytics page.
          </p>
          <h2 className="h3-size">More Information</h2>
          <p className="t-17">
            Hopefully that has clarified things for you and as was previously
            mentioned if there is something that you aren't sure whether you
            need or not it's usually safer to leave cookies enabled in case it
            does interact with one of the features you use on our site.
          </p>
        </section>
      ) : (
        <section>
          <h1 className="h3-size">
            Πολιτική cookie για το koryfaiesstoichimatikes.com
          </h1>
          <p className="t-17">
            Αυτή είναι η Πολιτική Cookies για το koryfaiesstoichimatikes.com,
            προσβάσιμο από το koryfaiesstoichimatikes.com
          </p>
          <h2 className="h3-size">Τι είναι τα cookies</h2>
          <p className="t-17">
            Όπως είναι κοινή πρακτική με όλους σχεδόν τους επαγγελματικούς
            ιστότοπους αυτό ο ιστότοπος χρησιμοποιεί cookies, τα οποία είναι
            μικροσκοπικά αρχεία που κατεβαίνουν στη δική σας υπολογιστή, για να
            βελτιώσετε την εμπειρία σας. Αυτή η σελίδα περιγράφει τι πληροφορίες
            που συγκεντρώνουν, πώς τις χρησιμοποιούμε και γιατί μερικές φορές
            χρειάζεται αποθηκεύστε αυτά τα cookies. Θα μοιραστούμε επίσης πώς
            μπορείτε να τα αποτρέψετε cookie από την αποθήκευση, ωστόσο αυτό
            μπορεί να υποβαθμιστεί ή να "σπάσει" ορισμένα στοιχεία της
            λειτουργικότητας του ιστότοπου.
          </p>
          <h2 className="h3-size">Πώς χρησιμοποιούμε τα cookies</h2>
          <p className="t-17">
            Χρησιμοποιούμε cookies για διάφορους λόγους που περιγράφονται
            παρακάτω. Δυστυχώς στις περισσότερες περιπτώσεις δεν υπάρχουν
            βιομηχανικές τυποποιημένες επιλογές για την απενεργοποίηση των
            cookies χωρίς πλήρη απενεργοποίηση της λειτουργικότητας και τις
            δυνατότητες που προσθέτουν σε αυτόν τον ιστότοπο. Συνιστάται να
            φύγετε σε όλα τα cookies αν δεν είστε σίγουροι αν τα χρειάζεστε ή
            όχι περίπτωση που χρησιμοποιούνται για την παροχή μιας υπηρεσίας που
            χρησιμοποιείτε.
          </p>
          <h2 className="h3-size">Απενεργοποίηση cookies</h2>
          <p className="t-17">
            Μπορείτε να αποτρέψετε τη ρύθμιση των cookies προσαρμόζοντας τις
            ρυθμίσεις ενεργοποιημένες το πρόγραμμα περιήγησής σας (ανατρέξτε στη
            Βοήθεια του προγράμματος περιήγησής σας για το πώς να το κάνετε
            αυτό). Να είστε ενήμεροι ότι η απενεργοποίηση των cookies θα
            επηρεάσει τη λειτουργικότητα αυτού και πολλούς άλλους ιστότοπους που
            επισκέπτεστε. Η απενεργοποίηση των cookies συνήθως γίνεται έχει ως
            αποτέλεσμα την απενεργοποίηση ορισμένων λειτουργιών και
            χαρακτηριστικών του αυτόν τον ιστότοπο. Επομένως, συνιστάται να μην
            απενεργοποιήσετε μπισκότα.
          </p>
          <h2 className="h3-size">Τα μπισκότα που ορίζουμε</h2>
          <p className="t-17">
            Cookies που χρησιμοποιούνται για τη διαμόρφωση του ιστότοπου Για να
            διασφαλιστεί ότι έχετε ένα καλό εμπειρία σε αυτόν τον ιστότοπο,
            προσφέρουμε μια λειτουργικότητα για να ρυθμίσετε τη δική σας
            προτιμήσεις για τον τρόπο λειτουργίας αυτού του ιστότοπου όταν τον
            χρησιμοποιείτε. Για να θυμάστε το δικό σας προτιμήσεις, πρέπει να
            ορίσουμε cookies έτσι ώστε αυτές οι πληροφορίες να μπορούν να είναι
            ανακαλείται κάθε φορά που αλληλεπιδράτε με μια σελίδα που
            επηρεάζεται από εσάς προτιμήσεις.
          </p>
          <h2 className="h3-size">Cookies τρίτων</h2>
          <p className="t-17">
            Σε ορισμένες ειδικές περιπτώσεις χρησιμοποιούμε επίσης cookies που
            παρέχονται από αξιόπιστο τρίτο κόμματα. Η ακόλουθη ενότητα
            περιγράφει ποιος τρίτος σας δημιουργεί cookies μπορεί να συναντήσετε
            μέσω αυτού του ιστότοπου.
          </p>
          <p className="t-17">
            Αυτός ο ιστότοπος χρησιμοποιεί το Google Analytics που είναι ένα από
            τα πιο διαδεδομένα και αξιόπιστη λύση αναλυτικών στοιχείων στον ιστό
            για να μας βοηθήσετε κατανοήσουμε πώς χρησιμοποιείτε τον ιστότοπο
            και τρόπους με τους οποίους μπορούμε να βελτιώσουμε τον ιστότοπο
            εμπειρία. Αυτά τα cookies μπορεί να παρακολουθούν πράγματα όπως πόσο
            καιρό έχετε ξοδέψτε στον ιστότοπο και στις σελίδες που επισκέπτεστε,
            ώστε να συνεχίσουμε παράγουν ελκυστικό περιεχόμενο.
          </p>
          <p className="t-17">
            Για περισσότερες πληροφορίες σχετικά με τα cookie του Google
            Analytics, ανατρέξτε στο επίσημο Σελίδα Google Analytics.
          </p>
          <h2 className="h3-size">Περισσότερες Πληροφορίες</h2>
          <p className="t-17">
            Ας ελπίσουμε ότι αυτό έχει ξεκαθαρίσει τα πράγματα για εσάς και όπως
            ήταν προηγουμένως ανέφερε εάν υπάρχει κάτι για το οποίο δεν είστε
            βέβαιοι εάν είστε χρειάζεται ή όχι, είναι συνήθως πιο ασφαλές να
            αφήσετε τα cookies ενεργοποιημένα σε περίπτωση που αυτό συμβεί
            αλληλεπιδρά με μία από τις λειτουργίες που χρησιμοποιείτε στον
            ιστότοπό μας.
          </p>
        </section>
      )}
    </main>
  );
};

export default Cookies;
