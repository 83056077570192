import React from "react";
import "./History.css";
import arrow from "../../images/arrow.svg";
import { LangContext } from "../../context/LangContext";

const History = () => {
  const lang = React.useContext(LangContext);

  return (
    <section className="history width">
      {lang === "en" ? (
        <>
          <h2 className="history__header">
            History of slot machines in casinos in Greece
          </h2>
          <p className="t-16 col-gr">
            Slot machines in casinos have become an important part of the
            entertainment industry in Greece. Their history is rich in events
            and interesting facts that tell how these machines won the hearts of
            Greeks and tourists.
          </p>
          <ul className="history__item-list">
            <li className="history__item">
              <img src={arrow} alt="arrow" className="history__arrow" />
              <h4 className="history__item-header">Early years of casinos:</h4>
              <p className="t-16 col-gr">
                At the beginning of the 20th century, the first slot machines
                began to appear in Greece. These machines were simple mechanical
                devices that attracted attention with their bright lights and
                the opportunity to win small prizes. The first machines were
                installed in taverns and cafes, where they quickly became
                popular among visitors.
              </p>
            </li>
            <li className="history__item">
              <img src={arrow} alt="arrow" className="history__arrow" />
              <h4 className="history__item-header">
                The Golden Age of casinos:
              </h4>
              <p className="t-16 col-gr">
                With the development of technology in the mid-20th century, slot
                machines became more complex and varied. New types of games such
                as poker machines and ‘one-armed bandits’ appeared, which
                allowed players to win cash prizes. During this period, slot
                machines became an integral part of entertainment complexes and
                casinos, attracting a large number of visitors.
              </p>
            </li>
            <li className="history__item">
              <img src={arrow} alt="arrow" className="history__arrow" />
              <h4 className="history__item-header">
                The era of electronic gaming:
              </h4>
              <p className="t-16 col-gr">
                In the 1980s, the era of electronic slot machines began in
                Greece. These machines were equipped with microprocessors and
                video displays, allowing for more complex and exciting games.
                Video slots with a variety of themes and bonus rounds became
                particularly popular, attracting players of all ages.
              </p>
            </li>
            <li className="history__item">
              <img src={arrow} alt="arrow" className="history__arrow" />
              <h4 className="history__item-header">
                Modern slot machines in casinos:
              </h4>
              <p className="t-16 col-gr">
                Today, slot machines in Greece continue to evolve. Modern
                machines offer stunning graphics, interactive features and huge
                jackpots. Online casinos have also contributed to the
                popularisation of slot machines, giving players the opportunity
                to enjoy their favourite games from any corner of the country.
              </p>
            </li>
          </ul>
          <p className="t-16 col-gr">
            Slot machines have come a long way from simple mechanical machines
            to modern electronic slots. Their history in Greece reflects the
            development of technology and the changing preferences of players.
            Whether you play classic machines or modern video slots, slot
            machines continue to bring joy and excitement to millions of people.
          </p>
        </>
      ) : (
        <>
          <h2 className="history__header">
            Ιστορία των κουλοχέρηδων στα καζίνο στην Ελλάδα
          </h2>
          <p className="t-16 col-gr">
            Οι κουλοχέρηδες στα καζίνο έχουν γίνει σημαντικό μέρος της
            ψυχαγωγίας βιομηχανία στην Ελλάδα. Η ιστορία τους είναι πλούσια σε
            γεγονότα και ενδιαφέρουσα γεγονότα που λένε πώς αυτές οι μηχανές
            κέρδισαν τις καρδιές των Ελλήνων και τουρίστες.
          </p>
          <ul className="history__item-list">
            <li className="history__item">
              <img src={arrow} alt="arrow" className="history__arrow" />
              <h4 className="history__item-header">
                Τα πρώτα χρόνια των καζίνο
              </h4>
              <p className="t-16 col-gr">
                Στις αρχές του 20ου αιώνα ξεκίνησαν οι πρώτοι κουλοχέρηδες να
                εμφανιστεί στην Ελλάδα. Αυτά τα μηχανήματα ήταν απλές μηχανικές
                συσκευές που τράβηξαν την προσοχή με τα έντονα φώτα τους και το
                ευκαιρία να κερδίσετε μικρά βραβεία. Εγκαταστάθηκαν τα πρώτα
                μηχανήματα σε ταβέρνες και καφετέριες, όπου γρήγορα έγιναν
                δημοφιλή επισκέπτες.
              </p>
            </li>
            <li className="history__item">
              <img src={arrow} alt="arrow" className="history__arrow" />
              <h4 className="history__item-header">
                Η Χρυσή Εποχή των καζίνο:
              </h4>
              <p className="t-16 col-gr">
                Με την ανάπτυξη της τεχνολογίας στα μέσα του 20ου αιώνα,
                κουλοχέρη οι μηχανές έγιναν πιο περίπλοκες και ποικίλες. Νέοι
                τύποι παιχνιδιών όπως π.χ εμφανίστηκαν μηχανές πόκερ και
                «μονόοπλοι ληστές», κάτι που επέτρεψε παίκτες να κερδίσουν
                χρηματικά έπαθλα. Την περίοδο αυτή έγιναν οι κουλοχέρηδες
                αναπόσπαστο μέρος συγκροτημάτων ψυχαγωγίας και καζίνο,
                προσελκύοντας μεγάλο αριθμό επισκεπτών.
              </p>
            </li>
            <li className="history__item">
              <img src={arrow} alt="arrow" className="history__arrow" />
              <h4 className="history__item-header">
                Η εποχή των ηλεκτρονικών παιχνιδιών:
              </h4>
              <p className="t-16 col-gr">
                Τη δεκαετία του 1980 ξεκίνησε στην Ελλάδα η εποχή των
                ηλεκτρονικών κουλοχέρηδων. Αυτά τα μηχανήματα ήταν εξοπλισμένα
                με μικροεπεξεργαστές και βίντεο οθόνες, επιτρέποντας πιο σύνθετα
                και συναρπαστικά παιχνίδια. Κουλοχέρηδες βίντεο με μια ποικιλία
                θεμάτων και γύρους μπόνους έγινε ιδιαίτερα δημοφιλές,
                προσελκύοντας παίκτες όλων των ηλικιών.
              </p>
            </li>
            <li className="history__item">
              <img src={arrow} alt="arrow" className="history__arrow" />
              <h4 className="history__item-header">
                Σύγχρονοι κουλοχέρηδες στα καζίνο:
              </h4>
              <p className="t-16 col-gr">
                Σήμερα, οι κουλοχέρηδες στην Ελλάδα συνεχίζουν να εξελίσσονται.
                Σύγχρονα μηχανήματα προσφέρουν εκπληκτικά γραφικά, διαδραστικά
                χαρακτηριστικά και τεράστια τζάκποτ. Τα διαδικτυακά καζίνο
                συνέβαλαν επίσης στη διάδοση του κουλοχέρη μηχανές, δίνοντας
                στους παίκτες την ευκαιρία να απολαύσουν το αγαπημένο τους
                παιχνίδια από οποιαδήποτε γωνιά της χώρας.
              </p>
            </li>
          </ul>
          <p className="t-16 col-gr">
            Οι κουλοχέρηδες έχουν διανύσει πολύ δρόμο από τις απλές μηχανικές
            μηχανές μέχρι σύγχρονες ηλεκτρονικές κουλοχέρηδες. Η ιστορία τους
            στην Ελλάδα αντανακλά την ανάπτυξη της τεχνολογίας και τις
            μεταβαλλόμενες προτιμήσεις των παικτών. Είτε παίζετε κλασικά
            μηχανήματα είτε μοντέρνα κουλοχέρηδες βίντεο, κουλοχέρηδες
            συνεχίζουν να φέρνουν χαρά και ενθουσιασμό σε εκατομμύρια ανθρώπους.
          </p>
        </>
      )}
    </section>
  );
};

export default History;
