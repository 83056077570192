import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";
import { LangContext } from "../../context/LangContext";

function Header({ changeLang }) {
  const lang = React.useContext(LangContext);
  const [isActive, setIsActive] = useState(false);
  const navigation = useNavigate();

  const handleLinkClick = () => {
    setIsActive(false);
  };

  const handleNavigation = (e, path, hash) => {
    e.preventDefault();
    navigation(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  };

  return (
    <>
      {lang === "en" ? (
        <div className="header__container">
          <div className="header__link-container">
            <Link to="/" className="header__link">
              Home page
            </Link>
            <Link to="/history" className="header__link">
              History
            </Link>
            <Link to="/games" className="header__link">
              Games
            </Link>
            <Link to="/links" className="header__link">
              Links
            </Link>
            <a
              href="/#contacts"
              className="header__link"
              onClick={(e) => {
                handleNavigation(e, "/", "contacts");
              }}
            >
              Contacts
            </a>
          </div>
          <div className="header__lang-container">
            <button
              className={`header__lang ${lang === "gr" ? "underline" : ""}`}
              onClick={() => changeLang("gr")}
            >
              GR
            </button>
            <button
              className={`header__lang ${lang === "en" ? "underline" : ""}`}
              onClick={() => changeLang("en")}
            >
              EN
            </button>
          </div>

          <div className="burger-menu">
            <div id="burger-menu">
              <img
                src={burgerMenu}
                alt="burger menu icon"
                className="burger__icon"
                onClick={() => setIsActive(true)}
              />
            </div>
            <nav
              className={`burger-menu__nav ${isActive ? "active" : ""}`}
              id="nav"
            >
              <img
                src={closeBtn}
                className="burger__close-btn"
                id="close-icon"
                alt="close menu icon"
                onClick={() => setIsActive(false)}
              />
              <ul className="burger-menu__nav-container">
                <li className="burger-menu__nav-link">
                  <Link
                    to="/"
                    className="header__link"
                    onClick={handleLinkClick}
                  >
                    Home page
                  </Link>
                </li>
                <li className="burger-menu__nav-link">
                  <Link
                    to="/history"
                    className="header__link"
                    onClick={handleLinkClick}
                  >
                    History
                  </Link>
                </li>
                <li className="burger-menu__nav-link">
                  <Link
                    to="/games"
                    className="header__link"
                    onClick={handleLinkClick}
                  >
                    Games
                  </Link>
                </li>
                <li className="burger-menu__nav-link">
                  <Link
                    to="/links"
                    className="header__link"
                    onClick={handleLinkClick}
                  >
                    Links
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <div className="header__container">
          <div className="header__link-container">
            <Link to="/" className="header__link">
              Αρχική σελίδα
            </Link>
            <Link to="/history" className="header__link">
              Ιστορία
            </Link>
            <Link to="/games" className="header__link">
              Παιχνίδια
            </Link>
            <Link to="/links" className="header__link">
              Εδαφος διά παιγνίδι γκολφ
            </Link>
            <a
              href="/#contacts"
              className="header__link"
              onClick={(e) => {
                handleNavigation(e, "/", "contacts");
              }}
            >
              Επαφές
            </a>
          </div>
          <div className="header__lang-container">
            <button
              className={`header__lang ${lang === "gr" ? "underline" : ""}`}
              onClick={() => changeLang("gr")}
            >
              GR
            </button>
            <button
              className={`header__lang ${lang === "en" ? "underline" : ""}`}
              onClick={() => changeLang("en")}
            >
              EN
            </button>
          </div>

          <div className="burger-menu">
            <div id="burger-menu">
              <img
                src={burgerMenu}
                alt="burger menu icon"
                className="burger__icon"
                onClick={() => setIsActive(true)}
              />
            </div>
            <nav
              className={`burger-menu__nav ${isActive ? "active" : ""}`}
              id="nav"
            >
              <img
                src={closeBtn}
                className="burger__close-btn"
                id="close-icon"
                alt="close menu icon"
                onClick={() => setIsActive(false)}
              />
              <ul className="burger-menu__nav-container">
                <li className="burger-menu__nav-link">
                  <Link
                    to="/"
                    className="header__link"
                    onClick={handleLinkClick}
                  >
                    Αρχική σελίδα
                  </Link>
                </li>
                <li className="burger-menu__nav-link">
                  <Link
                    to="/history"
                    className="header__link"
                    onClick={handleLinkClick}
                  >
                    Ιστορία
                  </Link>
                </li>
                <li className="burger-menu__nav-link">
                  <Link
                    to="/games"
                    className="header__link"
                    onClick={handleLinkClick}
                  >
                    Παιχνίδια
                  </Link>
                </li>
                <li className="burger-menu__nav-link">
                  <Link
                    to="/links"
                    className="header__link"
                    onClick={handleLinkClick}
                  >
                    Εδαφος διά παιγνίδι γκολφ
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
