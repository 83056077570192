import React from "react";
import "./Games.css";
import img1 from "../../images/clissic-slot-machines.webp";
import img2 from "../../images/3D-slots.webp";
import img3 from "../../images/mobile-slots.webp";
import img4 from "../../images/multigames.webp";
import arrow from "../../images/arrow.svg";
import threeStars from "../../images/3-stars.svg";
import fourStars from "../../images/4-stars.svg";
import pin from "../../images/pin.svg";
import { LangContext } from "../../context/LangContext";

const Games = () => {
  const lang = React.useContext(LangContext);
  return (
    <section className="games width">
      {lang === "en" ? (
        <>
          <h2 className="games__header">Machine games and their types in casinos</h2>
          <p className="t-16 col-gr">
            Slot machines offer a huge variety of games that can satisfy a
            variety of tastes and preferences. From classic ‘one-armed bandits’
            to modern video slots with unique themes and bonus features - every
            player will find something to his liking.
          </p>
        </>
      ) : (
        <>
          <h2 className="games__header">Παιχνίδια μηχανών και τα είδη τους στα καζίνο</h2>
          <p className="t-16 col-gr">
            Οι κουλοχέρηδες προσφέρουν μια τεράστια ποικιλία παιχνιδιών που
            μπορούν να ικανοποιήσουν μια ποικιλία των γεύσεων και των
            προτιμήσεων. Από τους κλασικούς «μονόοπλους ληστές» έως τους
            μοντέρνους κουλοχέρηδες βίντεο με μοναδικά θέματα και δυνατότητες
            μπόνους - κάθε παίκτης θα το κάνει βρει κάτι της αρεσκείας του.
          </p>
        </>
      )}

      <ul className="games__item-list">
        <li className="games__item">
          <div className="game__img-container">
            <img
              src={img1}
              alt="Classic slot machines"
              title="Classic slot machines"
              width="442"
              height="453"
              className="game__img"
            />
          </div>
          <div className="game__text-container">
            {lang === "en" ? (
              <>
                <h4 className="game__name">1. Classic slot machines in casinos:</h4>
                <p className="t-16">
                  Classic slot machines, also known as ‘one-armed bandits’, are
                  traditional machines with three reels and a minimum number of
                  paylines. These machines usually have simple symbols such as
                  fruit, BAR, 7 and bells. Their simplicity and nostalgic charm
                  make them popular among players who prefer a retro style.
                </p>
                <p className="t-20">Where can I play?</p>
              </>
            ) : (
              <>
                <h4 className="game__name">1. Κλασικοί κουλοχέρηδες στο καζίνο:</h4>
                <p className="t-16">
                  Οι κλασικοί κουλοχέρηδες, γνωστοί και ως «μονόοπλοι ληστές»,
                  είναι παραδοσιακές μηχανές με τρεις τροχούς και ελάχιστο
                  αριθμό γραμμές πληρωμής. Αυτά τα μηχανήματα έχουν συνήθως απλά
                  σύμβολα όπως π.χ φρούτα, BAR, 7 και καμπάνες. Η απλότητα και η
                  νοσταλγική γοητεία τους κάνουν είναι δημοφιλή μεταξύ των
                  παικτών που προτιμούν ένα ρετρό στυλ.
                </p>
                <p className="t-20">Πού μπορώ να παίξω;</p>
              </>
            )}

            <div className="game__info">
              <a
                href="https://www.tripadvisor.ru/Attraction_Review-g644224-d2093388-Reviews-Casino_Loutraki-Loutraki_Corinthia_Region_Peloponnese.html"
                target="_blank"
                rel="noreferrer"
                className="game__link-container"
              >
                <img
                  src={arrow}
                  alt="arrow link to the Casino"
                  title="arrow icon"
                  className="game__link-arrow"
                />
                <p className="t-16 game__link-name">Casino Loutraki</p>
              </a>
              <div className="game__review-container">
                <img src={fourStars} alt="4 stars" title="4 stars" />
                <p className="t-16 col-gr">
                  {lang === "en" ? "250 reviews" : "250 κριτικές"}
                </p>
              </div>
              <div className="game__adress-container">
                <img src={pin} alt="pin" title="pin" />
                <p className="t-16 col-gr">
                  48 Poseidonos Ave., Loutraki 203 00 Greece
                </p>
              </div>
              {lang === "en" ? (
                <>
                  <p className="t-14 col-gr">
                    is located in Loutraki, near Athens. It is a large casino
                    that offers a variety of slot machines, including classic
                    models.
                  </p>
                  <p className="t-14 col-gr">
                    Additional Services:
                    <br></br>The casino offers services for organising events
                    and conferences.
                    <br></br>There are thermal springs in the vicinity of
                    Loutraki, making this region a popular tourist destination.
                    <br></br>This establishment attracts locals and tourists
                    alike with its wide range of games and leisure facilities.
                  </p>
                </>
              ) : (
                <>
                  <p className="t-14 col-gr">
                    βρίσκεται στο Λουτράκι, κοντά στην Αθήνα. Είναι ένα μεγάλο
                    καζίνο που προσφέρει μια ποικιλία από κουλοχέρηδες,
                    συμπεριλαμβανομένων των κλασικών μοντέλων.
                  </p>
                  <p className="t-14 col-gr">
                    Πρόσθετες υπηρεσίες:
                    <br></br>Το καζίνο προσφέρει υπηρεσίες για τη διοργάνωση
                    εκδηλώσεων και συνέδρια.
                    <br></br>Υπάρχουν ιαματικές πηγές στην περιοχή του
                    Λουτρακίου, καθιστώντας αυτή την περιοχή δημοφιλή τουριστικό
                    προορισμό.
                    <br></br>Αυτή η εγκατάσταση προσελκύει ντόπιους και
                    τουρίστες με το ευρύ φάσμα των παιχνιδιών και των
                    εγκαταστάσεων αναψυχής.
                  </p>
                </>
              )}
            </div>
          </div>
        </li>

        <li className="games__item">
          <div className="game__img-container">
            <img
              src={img2}
              alt="3D slots"
              title="3D slots"
              width="442"
              height="453"
              className="game__img"
            />
          </div>
          <div className="game__text-container">
            {lang === "en" ? (
              <>
                <h4 className="game__name">2. 3D slots:</h4>
                <p className="t-16">
                  3D slots are a new step in the development of video slots,
                  offering stunning graphics and animations. These games create
                  a feeling of total immersion thanks to three-dimensional
                  visual effects and interactive elements. 3D slots often have
                  complex storylines and unique characters, which makes the game
                  not only a gamble but also exciting.
                </p>
                <p className="t-20">Where can I play?</p>
              </>
            ) : (
              <>
                <h4 className="game__name">2. Υποδοχές 3D:</h4>
                <p className="t-16">
                  Οι κουλοχέρηδες 3D είναι ένα νέο βήμα στην ανάπτυξη των
                  κουλοχέρηδων βίντεο, προσφέρει εκπληκτικά γραφικά και
                  κινούμενα σχέδια. Αυτά τα παιχνίδια δημιουργούν ένα αίσθηση
                  απόλυτης βύθισης χάρη στην τρισδιάστατη απεικόνιση εφέ και
                  διαδραστικά στοιχεία. Οι τρισδιάστατες υποδοχές συχνά έχουν
                  πολύπλοκα ιστορίες και μοναδικούς χαρακτήρες, γεγονός που
                  κάνει το παιχνίδι όχι μόνο α τζόγο αλλά και συναρπαστικό.
                </p>
                <p className="t-20">Πού μπορώ να παίξω;</p>
              </>
            )}

            <div className="game__info">
              <a
                href="https://www.tripadvisor.ru/Attraction_Review-g189473-d12731861-Reviews-Regency_Casino_Thessaloniki-Thessaloniki_Thessaloniki_Region_Central_Macedonia.html"
                target="_blank"
                rel="noreferrer"
                className="game__link-container"
              >
                <img
                  src={arrow}
                  alt="arrow link to the Casino"
                  title="arrow icon"
                  className="game__link-arrow"
                />
                <p className="t-16 game__link-name">
                  Regency Casino Thessaloniki
                </p>
              </a>
              <div className="game__review-container">
                <img src={threeStars} alt="3 stars" title="3 stars" />
                <p className="t-16 col-gr">
                  {lang === "en" ? "42 reviews" : "42 κριτικές"}
                </p>
              </div>
              <div className="game__adress-container">
                <img src={pin} alt="pin" title="pin" />
                <p className="t-16 col-gr">
                  12th km Thessaloniki - Airport, Thessaloniki 55103 Greece
                </p>
              </div>
              {lang === "en" ? (
                <>
                  <p className="t-14 col-gr">
                    One of the most famous casinos in Northern Greece, offering
                    a wide range of slot machines including 3D slots.
                  </p>
                  <p className="t-14 col-gr">
                    Casino Thessaloniki pays special attention to the safety and
                    comfort of its guests. The casino employs highly qualified
                    staff who provide a high level of service and help to create
                    an atmosphere conducive to a pleasant holiday. The casino
                    strictly complies with all rules and regulations to ensure
                    the fairness of games and protect the interests of players.
                  </p>
                </>
              ) : (
                <>
                  <p className="t-14 col-gr">
                    Ένα από τα πιο γνωστά καζίνο στη Βόρεια Ελλάδα, που
                    προσφέρει α μεγάλη γκάμα κουλοχέρηδων, συμπεριλαμβανομένων
                    των τρισδιάστατων κουλοχέρηδων.
                  </p>
                  <p className="t-14 col-gr">
                    Το Καζίνο Θεσσαλονίκης δίνει ιδιαίτερη σημασία στην ασφάλεια
                    και άνεση των επισκεπτών του. Το καζίνο απασχολεί υψηλά
                    καταρτισμένο προσωπικό που παρέχουν υψηλό επίπεδο υπηρεσιών
                    και βοηθούν στη δημιουργία ενός ατμόσφαιρα ευνοϊκή για
                    ευχάριστες διακοπές. Το καζίνο αυστηρά συμμορφώνεται με
                    όλους τους κανόνες και κανονισμούς για τη διασφάλιση της
                    δικαιοσύνης παιχνιδιών και προστατεύουν τα συμφέροντα των
                    παικτών.
                  </p>
                </>
              )}
            </div>
          </div>
        </li>

        <li className="games__item">
          <div className="game__img-container">
            <img
              src={img3}
              alt="Mobile Slots"
              title="Mobile Slots"
              width="442"
              height="453"
              className="game__img"
            />
          </div>
          <div className="game__text-container">
            {lang === "en" ? (
              <>
                <h4 className="game__name">3. Mobile Slots:</h4>
                <p className="t-16">
                  With the growing popularity of mobile devices, many slot
                  machines have been adapted for play on smartphones and
                  tablets. Mobile slots offer the same level of quality and
                  functionality as their desktop counterparts, but with the
                  convenience of playing on the go. They are optimised for touch
                  controls and allow you to enjoy your favourite games anytime,
                  anywhere.
                </p>
                <p className="t-20">Where can I play?</p>
              </>
            ) : (
              <>
                <h4 className="game__name">3. Κουλοχέρηδες για κινητά:</h4>
                <p className="t-16">
                  Με την αυξανόμενη δημοτικότητα των κινητών συσκευών, πολλοί
                  κουλοχέρηδες έχουν προσαρμοστεί για αναπαραγωγή σε smartphone
                  και tablet. Κινητός Οι κουλοχέρηδες προσφέρουν το ίδιο επίπεδο
                  ποιότητας και λειτουργικότητας με το δικό τους αντίστοιχες
                  επιτραπέζιων υπολογιστών, αλλά με την ευκολία να παίζετε στο
                  πάω. Είναι βελτιστοποιημένα για χειριστήρια αφής και σας
                  επιτρέπουν να απολαύσετε τα αγαπημένα σας παιχνίδια
                  οποτεδήποτε, οπουδήποτε.
                </p>
                <p className="t-20">Πού μπορώ να παίξω;</p>
              </>
            )}

            <div className="game__info">
              <a
                href="https://www.novibet.gr/casino"
                target="_blank"
                rel="noreferrer"
                className="game__link-container"
              >
                <img
                  src={arrow}
                  alt="arrow link to the Casino"
                  title="arrow icon"
                  className="game__link-arrow"
                />
                <p className="t-16 game__link-name">Novibet Casino</p>
              </a>
              <div className="game__review-container">
                <img src={fourStars} alt="4 stars" title="4 stars" />
                <p className="t-16 col-gr">
                  {lang === "en" ? "340 reviews" : "340 κριτικές"}
                </p>
              </div>
              <div className="game__adress-container">
                <img src={pin} alt="pin" title="pin" />
                <p className="t-16 col-gr">App Store</p>
              </div>
              {lang === "en" ? (
                <>
                  <p className="t-14 col-gr">
                    Novibet Casino is a popular online casino in Greece offering
                    a variety of games including slots, table games and live
                    casino.
                  </p>
                  <p className="t-14 col-gr">
                    How to Download
                    <br></br>iOS: Available on the App Store. Search for
                    ‘Novibet Casino’ and download the app to your device.
                    <br></br>Android: Available through the official Novibet
                    website, as casino apps are often not hosted on the Google
                    Play Store due to platform policies.
                  </p>
                </>
              ) : (
                <>
                  <p className="t-14 col-gr">
                    Το Novibet Casino είναι ένα δημοφιλές διαδικτυακό καζίνο
                    στην Ελλάδα που προσφέρει α ποικιλία παιχνιδιών, όπως
                    κουλοχέρηδες, επιτραπέζια παιχνίδια και ζωντανά καζίνο.
                  </p>
                  <p className="t-14 col-gr">
                    Τρόπος λήψης
                    <br></br>iOS: Διατίθεται στο App Store. Αναζήτηση για
                    «Novibet Καζίνο» και κατεβάστε την εφαρμογή στη συσκευή σας.
                    <br></br>Android: Διατίθεται μέσω της επίσημης Novibet
                    ιστοσελίδα, καθώς οι εφαρμογές καζίνο συχνά δεν
                    φιλοξενούνται στο Google Play Αποθήκευση λόγω πολιτικών
                    πλατφόρμας.
                  </p>
                </>
              )}
            </div>
          </div>
        </li>

        <li className="games__item">
          <div className="game__img-container">
            <img
              src={img4}
              alt="Classic slot machines"
              title="Classic slot machines"
              width="442"
              height="453"
              className="game__img"
            />
          </div>
          <div className="game__text-container">
            {lang === "en" ? (
              <>
                {" "}
                <h4 className="game__name">4. Multigames in casinos:</h4>
                <p className="t-16">
                  Multigames are slot machines that offer multiple games in one
                  device. Players can choose between different slots, video
                  poker and other games without having to change the machine.
                  This is a convenient solution for those who like variety and
                  want to try different games in one place.
                </p>
                <p className="t-20">Where can I play?</p>
              </>
            ) : (
              <>
                <h4 className="game__name">4. Multigames στα καζίνο:</h4>
                <p className="t-16">
                  Τα Multigames είναι κουλοχέρηδες που προσφέρουν πολλά
                  παιχνίδια σε ένα συσκευή. Οι παίκτες μπορούν να επιλέξουν
                  ανάμεσα σε διαφορετικούς κουλοχέρηδες, βίντεο πόκερ και άλλα
                  παιχνίδια χωρίς να χρειάζεται αλλαγή μηχανής. Αυτό είναι ένα
                  βολική λύση για όσους τους αρέσει η ποικιλία και θέλουν να
                  δοκιμάσουν διαφορετικά παιχνίδια σε ένα μέρος.
                </p>
                <p className="t-20">Πού μπορώ να παίξω;</p>
              </>
            )}

            <div className="game__info">
              <a
                href="https://www.tripadvisor.ru/Attraction_Review-g635613-d670287-Reviews-Casino_Rodos-Rhodes_Town_Rhodes_Dodecanese_South_Aegean.html"
                target="_blank"
                rel="noreferrer"
                className="game__link-container"
              >
                <img
                  src={arrow}
                  alt="arrow link to the Casino"
                  title="arrow icon"
                  className="game__link-arrow"
                />
                <p className="t-16 game__link-name">Casino Rodos</p>
              </a>
              <div className="game__review-container">
                <img src={fourStars} alt="4 stars" title="4 stars" />
                <p className="t-16 col-gr">
                  {lang === "en" ? "477 reviews" : "477 κριτικές"}
                </p>
              </div>
              <div className="game__adress-container">
                <img src={pin} alt="pin" title="pin" />
                <p className="t-16 col-gr">
                  4 Papanikolaou Georgiou, Rhodes 851 00 Greece
                </p>
              </div>
              {lang === "en" ? (
                <>
                  <p className="t-14 col-gr">
                    Slot Assortment:
                    <br></br>Slot Machines: The casino offers a wide range of
                    slot machines including classic slots, modern video slots
                    and multi-games.
                  </p>
                  <p className="t-14 col-gr">
                    Table Games: Popular table games such as roulette,
                    blackjack, poker, baccarat and craps are available.
                    <br></br>Real Time Casino: Live tables are available for
                    games with real dealers, allowing players to enjoy a
                    real-time gaming experience.
                  </p>
                </>
              ) : (
                <>
                  <p className="t-14 col-gr">
                    Ποικιλία κουλοχέρηδων:
                    <br></br>Κουλοχέρηδες: Το καζίνο προσφέρει μεγάλη γκάμα
                    κουλοχέρηδων μηχανήματα συμπεριλαμβανομένων των κλασικών
                    κουλοχέρηδων, των σύγχρονων κουλοχέρηδων βίντεο και πολλαπλά
                    παιχνίδια.
                  </p>
                  <p className="t-14 col-gr">
                    Επιτραπέζια παιχνίδια: Δημοφιλή επιτραπέζια παιχνίδια όπως
                    ρουλέτα, μπλάκτζακ, πόκερ, μπακαρά και ζάρια είναι
                    διαθέσιμα.
                    <br></br>Καζίνο σε πραγματικό χρόνο: Διατίθενται ζωντανά
                    τραπέζια για παιχνίδια με πραγματικούς αντιπροσώπους,
                    επιτρέποντας στους παίκτες να απολαμβάνουν ένα παιχνίδι σε
                    πραγματικό χρόνο εμπειρία.
                  </p>
                </>
              )}
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Games;
