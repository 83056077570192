import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import History from "../History/History";
import Games from "../Games/Games";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";
import Privacy from "../Privacy/Privacy";
import Cookies from "../Cookies/Cookies";
import { LangContext } from "../../context/LangContext";
import ScrollToTop from "../Scroll/Scroll";
import { useState } from "react";

function App() {
  const [lang, setLang] = useState('gr');

  function changeLang(lang) {
    setLang(lang);
    console.log(lang);
  }
  return (
    <div className="page">
      <LangContext.Provider value={lang}>
      <ScrollToTop />
      <Header changeLang={changeLang}/>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/history" element={<History />} />
        <Route path="/games" element={<Games />} />
        <Route path="/links" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookies" element={<Cookies />} />
      </Routes>
      <Footer />
      </LangContext.Provider>
    </div>
  );
}

export default App;
