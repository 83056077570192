import React from "react";
import { LangContext } from "../../context/LangContext";

const Privacy = () => {
  const lang = React.useContext(LangContext);

  return (
    <main className="privacy width">
      {lang === "en" ? (
        <section>
          <h1 className="h3-size">
            Privacy Policy for koryfaiesstoichimatikes.com
          </h1>
          <p className="t-17">
            At koryfaiesstoichimatikes.com, accessible from
            koryfaiesstoichimatikes.com, one of our main priorities is the
            privacy of our visitors. This Privacy Policy document contains types
            of information that is collected and recorded by
            koryfaiesstoichimatikes.com and how we use it. <br />
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>
          <h2 className="h3-size">Log Files</h2>
          <p className="t-17">
            koryfaiesstoichimatikes.com follows a standard procedure of using
            log files. These files log visitors when they visit websites. All
            hosting companies do this and a part of hosting services' analytics.
            The information collected by log files include internet protocol
            (IP) addresses, browser type, Internet Service Provider (ISP), date
            and time stamp, referring/exit pages, and possibly the number of
            clicks. These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information. Our Privacy Policy
            was created with the help of the Privacy Policy Generator.
          </p>
          <h2 className="h3-size">Our Advertising Partners</h2>
          <p className="t-17">
            Some of advertisers on our site may use cookies and web beacons. Our
            advertising partners are listed below. Each of our advertising
            partners has their own Privacy Policy for their policies on user
            data. For easier access, we hyperlinked to their Privacy Policies
            below.
          </p>
          <h2 className="h3-size">Privacy Policies</h2>
          <p className="t-17">
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of koryfaiesstoichimatikes.com.
            <br />
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on
            koryfaiesstoichimatikes.com, which are sent directly to users'
            browser. They automatically receive your IP address when this
            occurs. These technologies are used to measure the effectiveness of
            their advertising campaigns and/or to personalize the advertising
            content that you see on websites that you visit.
            <br />
            Note that koryfaiesstoichimatikes.com has no access to or control
            over these cookies that are used by third-party advertisers.
          </p>
          <h2 className="h3-size">Third Party Privacy Policies</h2>
          <p className="t-17">
            koryfaiesstoichimatikes.com's Privacy Policy does not apply to other
            advertisers or websites. Thus, we are advising you to consult the
            respective Privacy Policies of these third-party ad servers for more
            detailed information. It may include their practices and
            instructions about how to opt-out of certain options.  <br />
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites. What Are Cookies?
          </p>
          <h2 className="h3-size">Children's Information</h2>
          <p className="t-17">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
            <br />
            koryfaiesstoichimatikes.com does not knowingly collect any Personal
            Identifiable Information from children under the age of 13. If you
            think that your child provided this kind of information on our
            website, we strongly encourage you to contact us immediately and we
            will do our best efforts to promptly remove such information from
            our records.
          </p>
          <h2 className="h3-size">Online Privacy Policy Only</h2>
          <p className="t-17">
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in koryfaiesstoichimatikes.com. This
            policy is not applicable to any information collected offline or via
            channels other than this website.
          </p>
          <h2 className="h3-size">Consent</h2>
          <p className="t-17">
            By using our website, you hereby consent to our Privacy Policy and
            agree to its Terms and Conditions.
          </p>
        </section>
      ) : (
        <section>
          <h1 className="h3-size">
            Πολιτική Απορρήτου για το koryfaiesstoichimatikes.com
          </h1>
          <p className="t-17">
            Στο koryfaiesstoichimatikes.com, προσβάσιμο από
            koryfaiesstoichimatikes.com, μία από τις βασικές προτεραιότητές μας
            είναι το απόρρητο των επισκεπτών μας. Αυτό το έγγραφο Πολιτικής
            Απορρήτου περιέχει τύπους πληροφορίες που συλλέγονται και
            καταγράφονται από koryfaiesstoichimatikes.com και πώς το
            χρησιμοποιούμε. <br />
            Εάν έχετε επιπλέον ερωτήσεις ή χρειάζεστε περισσότερες πληροφορίες
            σχετικά με το δικό μας Πολιτική Απορρήτου, μη διστάσετε να
            επικοινωνήσετε μαζί μας.
          </p>
          <h2 className="h3-size">Αρχεία καταγραφής</h2>
          <p className="t-17">
            Το koryfaiesstoichimatikes.com ακολουθεί μια τυπική διαδικασία
            χρήσης του log αρχεία. Αυτά τα αρχεία καταγράφουν τους επισκέπτες
            όταν επισκέπτονται ιστότοπους. Όλη η φιλοξενία οι εταιρείες το
            κάνουν αυτό και ένα μέρος των αναλύσεων των υπηρεσιών φιλοξενίας. Ο
            Οι πληροφορίες που συλλέγονται από αρχεία καταγραφής περιλαμβάνουν
            πρωτόκολλο Διαδικτύου (IP) διευθύνσεις, τύπος προγράμματος
            περιήγησης, πάροχος υπηρεσιών Διαδικτύου (ISP), ημερομηνία και
            χρονική σήμανση, σελίδες παραπομπής/εξόδου και, πιθανώς, ο αριθμός
            των κλικ. Αυτά δεν συνδέονται με καμία προσωπική πληροφορία
            αναγνωρίσιμη. Ο σκοπός των πληροφοριών είναι η ανάλυση των τάσεων,
            τη διαχείριση του ιστότοπου, την παρακολούθηση της κίνησης των
            χρηστών στον ιστότοπο και συλλογή δημογραφικών πληροφοριών. Η
            Πολιτική Απορρήτου μας δημιουργήθηκε με τη βοήθεια του Privacy
            Policy Generator.
          </p>
          <h2 className="h3-size">Οι Διαφημιστικοί μας Συνεργάτες</h2>
          <p className="t-17">
            Ορισμένοι από τους διαφημιστές στον ιστότοπό μας ενδέχεται να
            χρησιμοποιούν cookies και web beacons. Μας Οι διαφημιστικοί
            συνεργάτες αναφέρονται παρακάτω. Κάθε διαφήμιση μας Οι συνεργάτες
            έχουν τη δική τους Πολιτική Απορρήτου για τις πολιτικές τους σχετικά
            με τα δεδομένα χρήστη. Για ευκολότερη πρόσβαση, συνδεθήκαμε με τις
            Πολιτικές απορρήτου τους παρακάτω.
          </p>
          <h2 className="h3-size">Πολιτικές απορρήτου</h2>
          <p className="t-17">
            Μπορείτε να συμβουλευτείτε αυτήν τη λίστα για να βρείτε την Πολιτική
            Απορρήτου για καθένα από αυτά διαφημιστικοί συνεργάτες του
            koryfaiesstoichimatikes.com.
            <br />
            Οι διακομιστές διαφημίσεων τρίτων ή τα δίκτυα διαφημίσεων
            χρησιμοποιούν τεχνολογίες όπως cookie, JavaScript ή Web Beacon που
            χρησιμοποιούνται στα αντίστοιχα διαφημίσεις και σύνδεσμοι που
            εμφανίζονται στο koryfaiesstoichimatikes.com, τα οποία αποστέλλονται
            απευθείας στο πρόγραμμα περιήγησης των χρηστών. Λαμβάνουν αυτόματα
            τη διεύθυνση IP σας όταν συμβεί αυτό. Αυτές οι τεχνολογίες
            χρησιμοποιούνται για να μετρούν την αποτελεσματικότητα των
            διαφημιστικών τους καμπανιών ή/και εξατομικεύστε το διαφημιστικό
            περιεχόμενο που βλέπετε σε ιστότοπους που εσείς επίσκεψη.
            <br />
            Σημειώστε ότι το koryfaiesstoichimatikes.com δεν έχει πρόσβαση ή
            έλεγχο αυτά τα cookies που χρησιμοποιούνται από τρίτους διαφημιστές.
          </p>
          <h2 className="h3-size">Πολιτικές απορρήτου τρίτων μερών</h2>
          <p className="t-17">
            Η Πολιτική Απορρήτου του koryfaiesstoichimatikes.com δεν ισχύει για
            άλλους διαφημιστές ή ιστότοπους. Επομένως, σας συμβουλεύουμε να
            συμβουλευτείτε το αντίστοιχες Πολιτικές απορρήτου αυτών των
            διακομιστών διαφημίσεων τρίτων για περισσότερα λεπτομερείς
            πληροφορίες. Μπορεί να περιλαμβάνει τις πρακτικές και τις οδηγίες
            τους σχετικά με το πώς να εξαιρεθείτε από ορισμένες επιλογές. <br />
            Μπορείτε να επιλέξετε να απενεργοποιήσετε τα cookies μέσω του
            ατομικού σας προγράμματος περιήγησης επιλογές. Για να μάθετε πιο
            λεπτομερείς πληροφορίες σχετικά με τη διαχείριση cookie με
            συγκεκριμένα προγράμματα περιήγησης ιστού, μπορεί να βρεθεί στα
            προγράμματα περιήγησης αντίστοιχες ιστοσελίδες. Τι είναι τα cookies;
          </p>
          <h2 className="h3-size">Πληροφορίες για παιδιά</h2>
          <p className="t-17">
            Ένα άλλο μέρος της προτεραιότητάς μας είναι η προσθήκη προστασίας
            για τα παιδιά ενώ χρησιμοποιώντας το διαδίκτυο. Ενθαρρύνουμε τους
            γονείς και τους κηδεμόνες να τηρούν, συμμετέχουν και/ή παρακολουθούν
            και καθοδηγούν τη διαδικτυακή τους δραστηριότητα.
            <br />
            Το koryfaiesstoichimatikes.com δεν συλλέγει εν γνώσει του κανένα
            Προσωπικό Αναγνωρίσιμα στοιχεία από παιδιά κάτω των 13 ετών. Εάν
            σκεφτείτε ότι το παιδί σας έδωσε αυτού του είδους τις πληροφορίες
            στο δικό μας ιστοσελίδα, σας ενθαρρύνουμε θερμά να επικοινωνήσετε
            μαζί μας αμέσως και εμείς θα καταβάλουμε κάθε δυνατή προσπάθεια για
            να αφαιρέσουμε αμέσως τέτοιες πληροφορίες από το δικό μας εγγραφές.
          </p>
          <h2 className="h3-size">Μόνο Διαδικτυακή Πολιτική Απορρήτου</h2>
          <p className="t-17">
            Αυτή η Πολιτική Απορρήτου ισχύει μόνο για τις διαδικτυακές μας
            δραστηριότητες και είναι έγκυρη για τους επισκέπτες της ιστοσελίδας
            μας σχετικά με τις πληροφορίες που αυτοί κοινοποιούνται και/ή
            συλλέγονται στο koryfaiesstoichimatikes.com. Αυτή η πολιτική είναι
            δεν ισχύει για πληροφορίες που συλλέγονται εκτός σύνδεσης ή μέσω
            καναλιών εκτός από αυτόν τον ιστότοπο.
          </p>
          <h2 className="h3-size">Συναίνεση</h2>
          <p className="t-17">
            Χρησιμοποιώντας τον ιστότοπό μας, συναινείτε με την Πολιτική
            Απορρήτου μας και συμφωνείτε με τους Όρους και τις Προϋποθέσεις του.
          </p>
        </section>
      )}
    </main>
  );
};

export default Privacy;
